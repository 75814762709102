<template lang="pug">
app-layout
  .page-error
    .page-error__content.content
      titled-image.mb-8(image="500" title="Внутренняя ошибка сервера" width=160)
        div Попробуйте перезагрузить страницу.
        div Если это не поможет, зайдите на страницу позже

      ui-btn.page-not-found__action(
        large
        secondary
        width=192
        @click="$router.go()"
      ) Перезагрузить

    get-apps(centered)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import GetApps from '@/components/app/GetApps.vue';
  import TitledImage from '@/components/TitledImage.vue';

  import { UiBtn } from '@/uikit';
  export default {
    name: 'page-error',
    components: { AppLayout, UiBtn, GetApps, TitledImage },
  };
</script>

<style lang="scss">
  .page-error {
    background-color: c('light', 'light');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    height: 100vh;
    width: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
